import { createRoot } from "react-dom/client";
import { Toaster } from "@primitives/toast/Toaster";
import { toast } from "@primitives/toast/use-toast";

import { waitForElementWithId } from "@utils/dom";
import { useEffect } from "react";

declare global {
  interface Window {
    toast?: typeof toast;
  }
}

type ServerToast = {
  level: "success" | "error";
  title: string;
  content: string;
};

const processServerData = (data: unknown) => {
  for (const t of data as ServerToast[]) {
    const level = t.level;
    toast({
      variant: level,
      title: t.title,
      description: t.content,
    });
  }
};

// making the toast function available globally
// so it can be used in other islands
window.toast = toast;

// listening for any htmx events
document.body.addEventListener("bn-toasts", function (e) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  processServerData((e as any).detail.value);
});

const App = () => {
  // processing any toasts included in the initial page load
  // doing this in an effect ensure that the toast island is ready
  // to display the toasts
  useEffect(() => {
    const dataElement = document.getElementById("toast-data");
    if (dataElement) {
      processServerData(JSON.parse(dataElement.textContent || "{}"));
    }
  }, []);

  return <Toaster />;
};

waitForElementWithId("toast-root").then((el) => {
  const root = createRoot(el);
  root.render(<App />);
});
