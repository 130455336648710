export function waitForElementWithId<T extends HTMLElement = HTMLElement>(
  selector: string,
): Promise<T> {
  return new Promise<T>((resolve) => {
    if (document.getElementById(selector)) {
      resolve(document.getElementById(selector) as T);
    }

    const observer = new MutationObserver(() => {
      if (document.getElementById(selector)) {
        resolve(document.getElementById(selector) as T);
        observer.disconnect();
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });
  });
}
